<template>
    <b-container fluid id="stocks">
        <b-field>
            <b-input
                    v-model="search_params"
                    placeholder="Hľadať..."
                    type="search"
                    icon="magnify"
                    v-on:keyup.enter.native="load">
            </b-input>
            <b-input
                    v-if="screen_width >= break_points[0]"
                    v-model="producer"
                    placeholder="Dodávateľ"
                    type="search"
                    v-on:keyup.enter.native="load">
            </b-input>
            <b-button type="is-info" outlined v-on:click="load">Filter</b-button>
            <b-select v-model="per_page" v-on:input="load">
                <option value="10">10 zásob</option>
                <option value="25">25 zásob</option>
                <option value="50">50 zásob</option>
                <option value="100">100 zásob</option>
                <option value="200">200 zásob</option>
            </b-select>
        </b-field>
        <b-table
                hoverable

                :data="data"
                :loading="loading"
                narrowed="true"

                paginated
                backend-pagination
                :total="total"
                :per-page="per_page"
                :current-page.sync="page"
                @page-change="onPageChange"
                aria-next-label="Ďalšia strana"
                aria-previous-label="Predchádzajúca strana"
                aria-page-label="Strana"
                aria-current-label="Aktuálna strana"

                backend-sorting
                default-sort-direction="asc"
                :default-sort="[sort_field, sort_order]"
                @sort="onSort"

                detailed
                detail-key="id"
                show-detail-icon
        >
            <template slot-scope="props">
                <b-table-column field="code" label="Kód" sortable>
                    {{ props.row.code }}
                </b-table-column>

                <b-table-column field="text" label="Text" sortable :visible="screen_width >= break_points[3]">
                    {{ props.row.text }}
                </b-table-column>

                <b-table-column field="producer" label="Dodávateľ" sortable :visible="screen_width >= break_points[3]">
                    {{ props.row.producer }}
                </b-table-column>

                <b-table-column field="availability" label="Dostupnosť" :visible="screen_width >= break_points[3]">
                    {{ props.row.availability }}
                </b-table-column>

                <b-table-column
                        field="quantity"
                        label="Stav zásob"
                        numeric
                        sortable
                        :visible="screen_width >= break_points[1]"
                >
                    <template v-if="props.row.sellout === true">
                        <b-tag size="is-medium" type="is-danger"><b>Vypredané</b></b-tag>
                    </template>
                    <template v-else-if="props.row.quantity === 0">
                        <b-tag size="is-medium" type="is-info"><b>Na objednávku</b></b-tag>
                    </template>
                    <template v-else>
                        {{ formatNumber(props.row.quantity) }}
                    </template>
                </b-table-column>

                <b-table-column
                        field="price"
                        label="Cena bez DPH"
                        numeric
                        sortable
                        :visible="screen_width >= break_points[0]"
                >
                    <b>{{ formatNumber(props.row.customer_price) }} €</b> / {{ props.row.unit }}
                </b-table-column>

                <b-table-column field="buy" label="" :width="buy_width">
                    <b-field position="is-right">
                        <template v-if="props.row.sellout === false">
                            <b-numberinput
                                    type="is-light"
                                    :size="buy_button"
                                    v-model="quantity[props.row.id]"
                                    min="1"
                                    controlsPosition="compact"
                            />
                            <p class="control">
                                <b-button
                                        type="is-danger"
                                        :size="buy_button"
                                        icon-right="cart"
                                        @click.native="addToCart(props.row)"
                                />
                            </p>
                        </template>
                    </b-field>
                </b-table-column>
            </template>

            <template slot="detail" slot-scope="props">
                <article class="media">
                    <div class="media-content">
                        <div class="content">
                            <p>
                                <strong>kód (preč. kód):</strong> {{ props.row.code }}
                                <br>
                                <strong>text:</strong> {{ props.row.text }}
                                <br>
                                <strong>dodávateľ:</strong> {{ props.row.producer }}
                                <br>
                                <strong>stav zásob:</strong>
                                    <template v-if="props.row.sellout === true">
                                        <b-tag size="is-small" type="is-danger"><b>Vypredané</b></b-tag>
                                    </template>
                                    <template v-else-if="props.row.quantity === 0">
                                        <b-tag size="is-small" type="is-info"><b>Na objednávku</b></b-tag>
                                    </template>
                                    <template v-else>
                                        {{ formatNumber(props.row.quantity) }} {{ props.row.unit }}
                                    </template>
                                <br>
                                <strong>cena bez DPH:</strong>
                                {{ formatNumber(props.row.customer_price) }}€ / {{ props.row.unit }}
                                <br>
                                <strong>dostupnosť:</strong> {{ props.row.availability }}
                            </p>
                        </div>
                    </div>
                </article>
            </template>
        </b-table>
    </b-container>
</template>

<script>
    import { mapActions } from "vuex";

    import stocks from "../api/stocks";
    import BField from "buefy/src/components/field/Field";
    import BInput from "buefy/src/components/input/Input";
    import BButton from "buefy/src/components/button/Button";
    import BSelect from "buefy/src/components/select/Select";
    import BTable from "buefy/src/components/table/Table";
    import BTableColumn from "buefy/src/components/table/TableColumn";
    import BNumberinput from "buefy/src/components/numberinput/Numberinput";
    import BTag from "buefy/src/components/tag/Tag";

    export default {
        name: "Stocks",
        components: {
            BTag,
            BNumberinput,
            BTableColumn,
            BTable,
            BSelect,
            BButton,
            BInput,
            BField,
        },
        data() {
            return {
                page: 1,
                total: 1,
                per_page: 50,
                sort_field: "code",
                sort_order: "asc",
                loading: false,
                search_params: null,
                producer: null,
                data: [],
                quantity: {},
                screen_width: 1920,
                buy_button: "Default",
                buy_width: 150,
                break_points: [540, 700, 900, 1200],
            }
        },
        created () {
            this.load()
            this.getWidth();
            this.timer = setInterval(this.getWidth, 1000)
        },
        beforeDestroy () {
            clearInterval(this.timer)
        },
        methods : {
            ...mapActions("cart", [
                "addProduct",
            ]),
            getWidth: function() {
                this.screen_width = screen.width;
                this.buy_button = (this.screen_width > 600) ? "Default" : "is-small";
                this.buy_width = (this.screen_width > 600) ? 150 : 100;
            },
            addToCart: function(row) {
                this.addProduct({
                    id: row.id,
                    code: row.code,
                    text: row.text,
                    price: row.price,
                    customer_price: row.customer_price,
                    customer_price_with_tax: row.customer_price_with_tax,
                    quantity: this.quantity[row.id],
                    unit: row.unit,
                });
            },
            load: function () {
                this.loading = true;
                return stocks.getStocks({
                    ordering:`${(this.sort_order === "asc") ? "" : "-"}${this.sort_field}`,
                    page: this.page,
                    page_size: this.per_page,
                    search: this.search_params,
                    producer: this.producer,
                })
                    .then(response => {
                        this.data = response.data.results || [];
                        this.total = response.data.count;
                    })
                    .catch( error => {
                        this.data = [];
                        this.total = 1;
                        this.page = 1;
                        throw error
                    })
                    .finally(() => {this.loading = false})
            },
            onPageChange(page) {
                this.page = page;
                this.load();
            },
            onSort(field, order) {
                this.sort_field = field;
                this.sort_order = order;
                this.load();
            },
            formatNumber(value) {
                let val = (value / 1).toFixed(2).replace(".", ",");
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
        }
    }
</script>

<style type="text/css">
    #stocks {
        left: 5%;
        right: 5%;
    }

    #stocks input[type="number"] {
        width: 80px;
    }
</style>
