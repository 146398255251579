<template>
    <b-container fluid id="customer-orders">
        <b-alert dismissible v-model="serverError" hide variant="danger">Chyba servera.</b-alert>
        <b-field>
            <b-input
                    v-model="search_params"
                    placeholder="Hľadať..."
                    type="search"
                    icon="magnify"
                    v-on:keyup.enter.native="search">
            </b-input>
             <b-select
                     placeholder="Stav"
                     v-model="state"
                     v-on:keyup.enter.native="search"
                     v-if="screen_width >= break_points[1]"
             >
                 <option value="" selected>---</option>
                 <option v-for="option in state_options" :value="option.value" :key="option.value">
                    {{ option.key }}
                 </option>
            </b-select>
            <b-select
                    placeholder="Spôsob platby"
                    v-model="payment"
                    v-on:keyup.enter.native="search"
                    v-if="screen_width >= break_points[2]"
            >
                <option value="" selected>---</option>
                <option v-for="option in payment_options" :value="option.value" :key="option.value">
                    {{ option.key }}
                </option>
            </b-select>
            <b-button type="is-info" outlined v-on:click="search">Hľadať</b-button>
            <b-select v-model="per_page" v-on:input="load" v-if="screen_width >= break_points[2]">
                <option value="10">10 zásob</option>
                <option value="25">20 zásob</option>
                <option value="50">50 zásob</option>
                <option value="100">100 zásob</option>
            </b-select>
        </b-field>
        <b-table
                hoverable

                :data="data"
                :loading="loading"

                paginated
                backend-pagination
                :total="total"
                :per-page="per_page"
                :current-page.sync="page"
                @page-change="onPageChange"
                aria-next-label="Ďalšia strana"
                aria-previous-label="Predchádzajúca strana"
                aria-page-label="Strana"
                aria-current-label="Aktuálna strana"

                backend-sorting
                default-sort-direction="asc"
                :default-sort="[sort_field, sort_order]"
                @sort="onSort"

                detailed
                detail-key="order_id"
                show-detail-icon
        >
            <template slot-scope="props">
                <b-table-column field="order_id" label="Kód objednávky" sortable>
                    <b>{{ props.row.order_id }}</b>
                </b-table-column>
                <b-table-column
                        field="customer_name" label="Zákazník" sortable :visible="screen_width >= break_points[1]"
                >
                    {{ props.row.customer_name }}
                </b-table-column>
                <b-table-column field="state" label="Stav" sortable :visible="screen_width >= break_points[0]">
                    {{ props.row.state_value }}
                </b-table-column>
                <b-table-column
                        field="total" label="Cena bez DPH" numeric sortable :visible="screen_width >= break_points[0]"
                >
                   <b>{{ formatNumber(props.row.total_price) }} €</b>
                </b-table-column>
                <b-table-column field="delivery" label="Doprava" sortable :visible="screen_width >= break_points[1]">
                    {{ props.row.delivery_name }}
                </b-table-column>
                <b-table-column
                        field="payment"
                        label="Spôsob platby"
                        sortable
                        :visible="screen_width >= break_points[1]"
                >
                    {{ props.row.payment_value }}
                </b-table-column>
                <b-table-column
                        field="shipped"
                        label="Dátum odoslania"
                        sortable
                        :visible="screen_width >= break_points[2]"
                >
                    {{ props.row.shipped }}
                </b-table-column>
                <b-table-column
                        field="delivered"
                        label="Dátum doručenia"
                        sortable
                        :visible="screen_width >= break_points[2]"
                >
                    {{ props.row.delivered }}
                </b-table-column>
                <b-table-column field="paid" label="Dátum platby" sortable :visible="screen_width >= break_points[2]">
                    {{ props.row.paid }}
                </b-table-column>
            </template>
            <template slot="detail" slot-scope="props">
                <article class="media">
                    <div class="media-content">
                        <div class="content">
                            <p>
                                <strong>Zákazník:</strong> {{ props.row.customer_name }} <br>
                                <strong>Stav:</strong> {{ props.row.state_value }} <br>
                                <strong>Cena bez DPH:</strong> {{ formatNumber(props.row.total_price) }} € <br>
                                <strong>Doprava:</strong> {{ props.row.delivery_name }} <br>
                                <strong>Spôsob platby:</strong> {{ props.row.payment_value }} <br>
                                <strong>Poznámka:</strong> {{ props.row.note }}
                            </p>
                        </div>
                    </div>
                </article>
                <table class="table is-hoverable" style="width: 100%">
                    <tr>
                        <th>Kód</th>
                        <th v-if="screen_width >= break_points[2]">Text</th>
                        <th>Množstvo</th>
                        <th v-if="screen_width >= break_points[1]">Cena</th>
                        <th>Spolu</th>
                    </tr>
                    <tr v-for="item in props.row.items" :key="item.code">
                        <td>{{ item.code }}</td>
                        <td v-if="screen_width >= break_points[2]">{{ item.text }}</td>
                        <td>{{ item.quantity }}</td>
                        <td v-if="screen_width >= break_points[1]">{{ formatNumber(item.price) }} € </td>
                        <td>{{ formatNumber(item.total) }} € </td>
                    </tr>
                </table>
            </template>
        </b-table>
    </b-container>
</template>

<script>
    import orders from "../api/orders";

    import { BAlert } from "bootstrap-vue";

    import BField from "buefy/src/components/field/Field";
    import BInput from "buefy/src/components/input/Input";
    import BButton from "buefy/src/components/button/Button";
    import BSelect from "buefy/src/components/select/Select";
    import BTable from "buefy/src/components/table/Table";
    import BTableColumn from "buefy/src/components/table/TableColumn";

    export default {
        name: "Orders",
        components: {BAlert, BTableColumn, BTable, BSelect, BButton, BInput, BField},
        data() {
            return {
                page: 1,
                total: 1,
                per_page: 50,
                sort_field: "code",
                sort_order: "asc",
                loading: false,
                search_params: null,
                data: [],
                payment: "",
                state: "",
                serverError: false,
                payment_options: [],
                state_options: [],
                screen_width: null,
                break_points: [360, 720, 1100],
            }
        },
        created () {
            this.load();
            this.getWidth();
            this.timer = setInterval(this.getWidth, 1000)
            return orders.getOptions()
                .then(response => {
                    this.payment_options = response.data.payment;
                    this.state_options = response.data.state;
                })
                .catch(() => this.serverError = true)
        },
        beforeDestroy () {
            clearInterval(this.timer)
        },
        methods : {
            getWidth: function() {
                this.screen_width = screen.width;
            },
            load: function () {
                this.loading = true;
                return orders.getCustomerOrders({
                    ordering:`${(this.sort_order === "asc") ? "" : "-"}${this.sort_field}`,
                    page: this.page,
                    page_size: this.per_page,
                    search: this.search_params,
                    state: this.state,
                    payment: this.payment,
                })
                    .then(response => {
                        this.data = response.data.results || [];
                        this.total = response.data.count;
                    })
                    .catch( error => {
                        this.data = [];
                        this.total = 1;
                        this.page = 1;
                        throw error
                    })
                    .finally(() => {this.loading = false})
            },
            search: function () {
                this.load();
            },
            onPageChange(page) {
                this.page = page;
                this.load();
            },
            onSort(field, order) {
                this.sort_field = field;
                this.sort_order = order;
                this.load();
            },
            formatNumber(value) {
                let val = (value / 1).toFixed(2).replace(".", ",");
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
        }
    }
</script>

<style type="text/css">
    #orders {
        left: 5%;
        right: 5%;
    }
</style>