<template>
    <div id="profile">
        <b-modal
                v-model="isChangePassModalActive"
                title="Zmeniť heslo"
                @show="clean_modal"
                @hidden="clean_modal"
                @ok="change_pass"
                no-fade="true"
                size="md"
                hide-backdrop="true"
        >
            <form ref="form">
                 <b-alert dismissible v-model="serverError" hide variant="danger">
                    Chyba servera skúste to neskôr.
                </b-alert>
                <b-form-group
                        label="Staré heslo"
                        label-for="old_password"
                        invalid-feedback="Nesprávne heslo."
                        :state="oldPassValidation"
                >
                    <b-form-input
                            id="old_password"
                            type="password"
                            v-model="old_password"
                            :state="oldPassValidation"
                            trim
                            required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                        label="Nové heslo (1/2)"
                        label-for="new_password_1"
                        invalid-feedback="Nové heslo sa nezhoduje alebo je príliš jednoduché. Musí obsahovať aspoň 8 znakov, jedno číslo a jeden špeciálny znak alebo veľké písmeno."
                        :state="newPassValidation"
                >
                    <b-form-input
                            id="new_password_1"
                            type="password"
                            v-model="new_password_1"
                            :state="newPassValidation"
                            trim
                            required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                        label="Nové heslo (2/2)"
                        label-for="new_password_2"
                >
                    <b-form-input
                            id="new_password_2"
                            type="password"
                            v-model="new_password_2"
                            :state="newPassValidation"
                            trim
                            required
                    ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>

        <b-container fluid>
            <b-row>
                <b-col lg="4">
                    <h1>Základné info</h1>
                    <b-form-group label="Prihlasovacie meno:" label-for="username">
                        <b-input-group>
                            <b-form-input type="text" id="username" v-model="username" disabled></b-form-input>
                            <b-button type="is-warning" v-on:click="isChangePassModalActive = true">Zmeniť heslo</b-button>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="Email:" label-for="email">
                        <b-form-input type="text" id="email" v-model="email" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="Telefónne číslo:" label-for="phone">
                        <b-form-input type="text" id="phone" v-model="phone" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="Kategória zakáznika:" label-for="customer_type">
                        <b-form-input type="text" id="customer_type" v-model="customer_type" disabled></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <h1>Fakturačné údaje</h1>
                    <b-form-group label="Názov:" label-for="billing_name">
                        <b-form-input type="text" id="billing_name" v-model="billing_name" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="Ulica:" label-for="billing_street">
                        <b-form-input type="text" id="billing_street" v-model="billing_street" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="Mesto:" label-for="billing_city">
                        <b-form-input type="text" id="billing_city" v-model="billing_city" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="PSČ:" label-for="billing_postal_code">
                        <b-form-input type="text" id="billing_postal_code" v-model="billing_postal_code" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="Región:" label-for="billing_region">
                        <b-form-input type="text" id="billing_region" v-model="billing_region" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="Krajina:" label-for="billing_country">
                        <b-form-input type="text" id="billing_country" v-model="billing_country" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="IČO:" label-for="billing_business_id">
                        <b-form-input type="text" id="billing_business_id" v-model="billing_business_id" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="DIČ:" label-for="billing_tax_id">
                        <b-form-input type="text" id="billing_tax_id" v-model="billing_tax_id" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="IČ-DPH:" label-for="billing_vat">
                        <b-form-input type="text" id="billing_vat" v-model="billing_vat" disabled></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <h1>Dodacia adresa</h1>
                    <b-form-group label="Názov:" label-for="shipping_name">
                        <b-form-input type="text" id="shipping_name" v-model="shipping_name" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="Ulica:" label-for="shipping_street">
                        <b-form-input type="text" id="shipping_street" v-model="shipping_street" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="Mesto:" label-for="shipping_city">
                        <b-form-input type="text" id="shipping_city" v-model="shipping_city" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="PSČ:" label-for="shipping_postal_code">
                        <b-form-input type="text" id="shipping_postal_code" v-model="shipping_postal_code" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="Región:" label-for="shipping_region">
                        <b-form-input type="text" id="shipping_region" v-model="shipping_region" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="Krajina:" label-for="shipping_country">
                        <b-form-input type="text" id="shipping_country" v-model="shipping_country" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group label="Telefónne číslo:" label-for="shipping_phone">
                        <b-form-input type="text" id="shipping_phone" v-model="shipping_phone" disabled></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import auth from "../api/auth";

    export default {
        name: "Profile",
        data() {
            return {
                isChangePassModalActive: false,
                old_password: null,
                new_password_1: null,
                new_password_2: null,

                oldPassValidation: null,
                newPassValidation: null,
                serverError: false,

                username: null,
                email: null,
                phone: null,
                customer_type: null,
                billing_name: null,
                billing_street: null,
                billing_city: null,
                billing_postal_code: null,
                billing_region: null,
                billing_country: null,
                billing_business_id: null,
                billing_tax_id: null,
                billing_vat: null,
                shipping_name: null,
                shipping_phone: null,
                shipping_street: null,
                shipping_city: null,
                shipping_postal_code: null,
                shipping_region: null,
                shipping_country: null,
            }
        },
        created () {
            this.load()
        },
        methods : {
            load: function () {
                return auth.profile()
                    .then(response => {
                        this.username = response.data.username;
                        this.email = response.data.email;
                        this.phone = response.data.phone;
                        this.customer_type = response.data.customer_type;

                        this.billing_name = response.data.billing_name;
                        this.billing_street = response.data.billing_street;
                        this.billing_city = response.data.billing_city;
                        this.billing_postal_code = response.data.billing_postal_code;
                        this.billing_region = response.data.billing_region;
                        this.billing_country = response.data.billing_country;
                        this.billing_business_id = response.data.billing_business_id;
                        this.billing_tax_id = response.data.billing_tax_id;
                        this.billing_vat = response.data.billing_vat;

                        this.shipping_name = response.data.shipping_name;
                        this.shipping_phone = response.data.shipping_phone;
                        this.shipping_street = response.data.shipping_street;
                        this.shipping_city = response.data.shipping_city;
                        this.shipping_postal_code = response.data.shipping_postal_code;
                        this.shipping_region = response.data.shipping_region;
                        this.shipping_country = response.data.shipping_country;
                    })
            },
            clean_error: function (){
                this.oldPassValidation = null;
                this.newPassValidation = null;
                this.serverError = false;
            },
            clean_modal: function() {
                this.old_password = null;
                this.new_password_1 = null;
                this.new_password_2 = null;
                this.clean_error();
            },
            close: function(){
                this.isChangePassModalActive = false;
                this.clean_modal();
            },
            change_pass: function (evt) {
                evt.preventDefault();
                this.clean_error();
                if (this.old_password && this.new_password_1 && this.new_password_2) {
                    return auth.change_password(this.old_password, this.new_password_1, this.new_password_2)
                    .then(() => {
                        this.close();
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            this.oldPassValidation = false;
                        } else if (error.response.status === 406) {
                            this.newPassValidation = false;
                        } else {
                            this.serverError = true;
                        }
                    })
                } else {
                     this.oldPassValidation = false;
                    this.newPassValidation = false;
                }
            },
        }
    }
</script>
