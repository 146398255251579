const CART_NAME = "CART";

const initialState = {
    cart: JSON.parse(localStorage.getItem(CART_NAME)) || {},
};

const getters = {
    getCart: state => state.cart,
};

const actions = {
    addProduct: ({ commit }, product) => {
        commit("ADD_PRODUCT", product);
    },
    removeProduct: ({ commit }, id) => {
        commit("REMOVE_PRODUCT", id);
    },
    updateProduct: ({ commit }, product) => {
        commit("UPDATE_PRODUCT", product);
    },
    updateCart: ({ commit }, cart) => {
        commit("UPDATE_CART", cart);
    },
    cleanCart: ({ commit }) => {
        commit("CLEAN_CART");
    },
};

const mutations = {
    ADD_PRODUCT: (state, { id, code, text, price, customer_price, customer_price_with_tax, quantity, unit }) => {
        if (!(id in state.cart)) {
            state.cart[id] = {
                code: code,
                text: text,
                price: price,
                customer_price: customer_price,
                customer_price_with_tax: customer_price_with_tax,
                unit: unit
            };
        }
        state.cart[id]["quantity"] = (state.cart[id]["quantity"] || 0) + (quantity || 1);
        localStorage.setItem(CART_NAME, JSON.stringify(state.cart));
    },
    REMOVE_PRODUCT: (state, id) => {
        delete state.cart[id];
        localStorage.setItem(CART_NAME, JSON.stringify(state.cart));
    },
    UPDATE_PRODUCT: (state, { id, quantity }) => {
        state.cart[id]["quantity"] = (quantity || 1);
        localStorage.setItem(CART_NAME, JSON.stringify(state.cart));
    },
    UPDATE_CART: (state, cart) => {
        state.cart = cart;
        localStorage.setItem(CART_NAME, JSON.stringify(state.cart));
    },
    CLEAN_CART: (state) => {
        state.cart = {};
        localStorage.removeItem(CART_NAME);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};