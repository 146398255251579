import Vue from "vue"

import App from "./App.vue"
import router from "./router"
import store from "./store"

import "./assets/css/custom-buefy.css";

import BootstrapVue from "bootstrap-vue"
import { NavbarPlugin } from 'bootstrap-vue';
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

Vue.use(BootstrapVue);
Vue.use(NavbarPlugin);

Vue.config.productionTip = false;

new Vue({
    el: "#app",
    router,
    store,
    render: h => h(App),
});
