<template>
    <div id="app">
        <ws-login v-if="!isAuthenticated"></ws-login>
        <ws-navbar v-if="isAuthenticated"></ws-navbar>
        <div id="ws-body" v-if="isAuthenticated">
            <router-view></router-view>
        </div>
        <ws-footer v-if="isAuthenticated"></ws-footer>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import Navbar from "./components/Navbar";
    import Footer from "./components/Footer";
    import Login from "./views/Login";

    export default {
        name: "app",
        components: {
            "ws-navbar": Navbar,
            "ws-footer": Footer,
            "ws-login": Login,
        },
        computed: {
            ...mapGetters("auth", {
                isAuthenticated: "isAuthenticated",
            }),
        },
    };
</script>

<style>
    #app {
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        min-width: 100%;
        display: table;
    }
    #ws-body {
        padding-top: 15px;
        padding-bottom: 10px;
        width: 100%;
        display: table;
    }
</style>
