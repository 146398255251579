import session from "./session";

export default {
    login(username, password) {
        return session.post("/v1/login/", {}, {auth: {username: username, password: password }})
    },
    logout() {
        return session.post("/v1/logout/", {})
    },
    profile() {
        return session.get("/v1/account/", {})
    },
    change_password(old_pass, new_pass_1, new_pass_2) {
        return session.patch(
            "/v1/change_password/",
            {old_password: old_pass, new_password_1: new_pass_1, new_password_2: new_pass_2}
            )
    },
};
