import session from "./session";

export default {
    getOrders(params) {
        return session.get("/v1/orders/", {params: params})
    },
    getCustomerOrders(params) {
        return session.get("/v1/orders/full-list/", {params: params})
    },
    getOptions() {
        return session.get("/v1/orders/options/")
    },
    createOrder(params) {
        return session.post("/v1/orders/order/", params)
    },
};
