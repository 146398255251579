<template>
    <b-container fluid id="order">
        <b-alert dismissible v-model="serverError" hide variant="danger">Chyba servera.</b-alert>
        <template v-if="Object.keys(cart).length === 0">
            <div class="empty-cart">Nákupný košik je prázdny</div>
        </template>
        <template v-else>
            <b-form @submit="createOrder">
                <b-row>
                    <b-col offset-lg="2" lg="8">
                        <b-table :data="data">
                            <template slot-scope="props">
                                <b-table-column field="code" label="Kód" sortable>
                                    {{ props.row.code }}
                                </b-table-column>
                                <b-table-column field="text" label="Text" sortable>
                                    {{ props.row.text }}
                                </b-table-column>
                                <b-table-column field="quantity" label="Množstvo" sortable width="150">
                                    <b-numberinput
                                        type="is-light"
                                        v-model="cart[props.row.id].quantity"
                                        v-on:input="update(props.row.id)"
                                        min="1"
                                        controlsPosition="compact"
                                    />
                                </b-table-column>
                                <b-table-column field="price" label="Cena" sortable>
                                    <b>{{ formatNumber(props.row.price) }} €</b> / {{ props.row.unit }}
                                </b-table-column>
                                <b-table-column field="price" label="Cena (zákazník)" sortable>
                                    <b>{{ formatNumber(props.row.customer_price) }} €</b> / {{ props.row.unit }}
                                </b-table-column>
                                <b-table-column field="total" label="Spolu bez DPH" sortable>
                                    <b>{{ formatNumber( props.row.quantity * props.row.customer_price) }} €</b>
                                </b-table-column>
                                <b-table-column field="" label="" width="50">
                                    <b-field position="is-right">
                                        <template>
                                            <bu-button
                                                    type="is-danger"
                                                    icon-right="delete"
                                                    v-on:click="remove(props.row.id)"
                                            >vymazať</bu-button>
                                        </template>
                                    </b-field>
                                </b-table-column>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6" offset-lg="2" lg="4">
                        <b-field label="Platba">
                            <b-select placeholder="Platba" v-model="payment" v-on:input="load()" expanded required>
                                <option v-for="option in payment_options" :value="option.value" :key="option.value">
                                    {{ option.key }}
                                </option>
                            </b-select>
                        </b-field>
                    </b-col>
                    <b-col md="6" lg="4">
                        <b-field label="Doprava">
                            <b-select placeholder="Doprava" v-model="delivery" v-on:input="load()" expanded required>
                                <option v-for="option in delivery_options" :value="option.id" :key="option.name">
                                    {{ option.name }}
                                </option>
                            </b-select>
                        </b-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col offset-lg="2" lg="8">
                        <b-field label="Poznámka">
                            <b-input maxlength="300" v-model="note" type="textarea"></b-input>
                        </b-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6" offset-md="2" md="6" offset-lg="5" lg="3">
                        <p class="part-title">Doprava:</p>
                    </b-col>
                    <b-col sm="6" md="4" lg="2">
                        <p class="part-price"><b>{{ formatNumber(delivery_price) }} €</b></p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6" offset-md="2" md="6" offset-lg="5" lg="3">
                        <p class="part-title">Cena celkom:</p>
                    </b-col>
                    <b-col sm="6" md="4" lg="2">
                        <p class="part-price"><b>{{ formatNumber(total) }} €</b></p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6" offset-md="2" md="6" offset-lg="5" lg="3">
                        <p class="part-title">DPH:</p>
                    </b-col>
                    <b-col sm="6" md="4" lg="2">
                        <p class="part-price"><b>{{ formatNumber(total_tax) }} €</b></p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6" offset-md="2" md="6" offset-lg="5" lg="3">
                        <p class="total-title"><strong>Cena celkom s DPH:</strong></p>
                    </b-col>
                    <b-col sm="6" md="4" lg="2">
                        <p class="total-price"><b>{{ formatNumber(total_with_tax) }} €</b></p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="8" offset-md="2" md="7" offset-lg="2" lg="6">
                        <b-checkbox v-model="agreement" required>
                            Vyjadrujem svoj súhlas s
                            <a href="/obchodne-podmienky" target="_blank" class="form-link">obchodnými podmienkami</a>
                        </b-checkbox>
                    </b-col>
                    <b-col sm="4" md="3" lg="2">
                        <b-button block variant="outline-success" type="submit">Objednať</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
    </b-container>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";

    import orders from "../api/orders";
    import stocks from "../api/stocks";

    import BTable from "buefy/src/components/table/Table";
    import BTableColumn from "buefy/src/components/table/TableColumn";
    import BField from "buefy/src/components/field/Field";
    import BSelect from "buefy/src/components/select/Select";
    import BInput from "buefy/src/components/input/Input";
    import BCheckbox from "buefy/src/components/checkbox/Checkbox";
    import BuefyButton from "buefy/src/components/button/Button";
    import BNumberinput from "buefy/src/components/numberinput/Numberinput";

    export default {
        name: "Order",
        components: {
            BNumberinput,
            "bu-button": BuefyButton,
            BCheckbox,
            BInput,
            BSelect,
            BField,
            BTableColumn,
            BTable,
        },
        data() {
            return {
                data: [],
                total: 0,
                total_tax: 0,
                total_with_tax: 0,
                payment: null,
                payment_options: [],
                delivery: null,
                delivery_price: null,
                delivery_price_with_tax: null,
                delivery_options: [],
                note: null,
                agreement: null,
                cart: {},
                serverError: false,
            }
        },
        computed: {
            ...mapGetters("cart", [
                "getCart",
            ]),
        },
        created: function () {
            this.check_interval = setInterval(() => this.check(), 3000);  // set auto check of stocks price

            this.load();
            return orders.getOptions()
                .then(response => {
                    this.payment_options = response.data.payment;
                    this.delivery_options = response.data.delivery;
                })
                .catch(() => this.serverError = true)
        },
        beforeDestroy: function() {
            clearInterval(this.check_interval);
        },
        methods: {
            ...mapActions("cart", [
                "removeProduct", "updateProduct", "updateCart", "cleanCart",
            ]),
            formatNumber(value) {
                let val = (value / 1).toFixed(2).replace(".", ",");
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            load: function () {
                this.cart = this.getCart;
                this.data = [];
                this.total = 0;
                this.total_with_tax = 0;

                for (let [key, value] of Object.entries(this.cart)) {
                    if (key && value.quantity) {
                        this.cart[key].quantity = Number(this.cart[key].quantity);
                        this.data.push(Object.assign({}, {id: Number(key)}, value));
                        this.total += value.customer_price * value.quantity;
                        this.total_with_tax += value.customer_price_with_tax * value.quantity;
                    }
                }

                this.delivery_options.forEach((delivery) => {
                    if (delivery.id === this.delivery) {
                        this.delivery_price = (this.total < delivery.free_from) ? delivery.price : 0;
                        this.delivery_price_with_tax = (this.total < delivery.free_from) ? delivery.price_with_tax : 0;
                    }
                });

                this.total += this.delivery_price;
                this.total_with_tax += this.delivery_price_with_tax;
                this.total_tax = this.total_with_tax - this.total;
            },
            remove: function (id) {
                this.removeProduct(id);
                this.load();
            },
            update: function(id) {
                this.updateProduct({ id: id, quantity: this.cart[id].quantity });
                this.load();
            },
            check: function() {
                if (Object.keys(this.cart).length > 0) {
                    let ids = Object.keys(this.cart);

                    return stocks.checkStocks(ids)
                        .then(response => {
                            let new_cart = {};

                            response.data.forEach((stock) => {
                                new_cart[stock.id] = this.cart[stock.id];
                                new_cart[stock.id].price = stock.price;
                                new_cart[stock.id].customer_price = stock.customer_price;
                                new_cart[stock.id].customer_price_with_tax = stock.customer_price_with_tax;
                            });

                            this.updateCart(new_cart);
                            this.load();
                        })
                        .catch(() => this.serverError = true)
                }
            },
            createOrder: function (event) {
                event.preventDefault();

                let items = [];
                this.data.forEach((item) => {
                    items.push({pk: item.id, quantity: item.quantity});
                });

                return orders.createOrder({
                    payment: this.payment,
                    delivery: this.delivery,
                    note: this.note,
                    items: items,
                })
                    .then(() => this.cleanOrder(event))
                    .catch(() => this.serverError = true)
            },
            cleanOrder: function (event) {
                return this.cleanCart(event)
                    .then(() => this.$router.push("/objednavky"))
            }
        }
    }
</script>

<style scoped>
    #order {
        left: 5%;
        right: 5%;
    }

    #order .total-title {
        font-size: 2em;
        text-align: right;
    }

    #order .total-price {
        font-size: 2.2em;
        text-align: right;
    }

    #order .part-title {
        font-size: 1.6em;
        text-align: right;
    }

    #order .part-price {
        font-size: 1.8em;
        text-align: right;
    }

    #order .empty-cart {
        padding-top: 30px;
        top: 30px;
        font-size: 2em;
        text-align: center;
    }
    #order input[type="number"] {
        width: 80px;
    }
</style>
