<template>
     <div class="ws-footer">
         © 2020 Karki, spol. s r.o., Všetky práva vyhradené.
     </div>
</template>

<script>
    export default {
        name: "base-footer",
    };
</script>

<style>
    .ws-footer {
        padding: 5px;
        background-color: #efefef;
        text-align: center;
    }
</style>