import Vue from "vue";
import Router from "vue-router";

import Conditions from "../views/Conditions";
import Files from "../views/Files";
import Login from "../views/Login";
import Lost from "../views/Lost";
import Order from "../views/Order";
import Orders from "../views/Orders";
import CustomerOrders from "../views/CustomerOrders";
import Profile from "../views/Profile";
import Stocks from "../views/Stocks";

import store from "../store";


const requireAuthenticated = (to, from, next) => {
    store.dispatch("auth/isLoggedIn")
        .then(() => {
            if (!store.getters["auth/isAuthenticated"]) {
                next("/prihlasit");
            } else {
                next();
            }
        });
};

const requireUnauthenticated = (to, from, next) => {
    store.dispatch("auth/isLoggedIn")
        .then(() => {
            if (store.getters["auth/isAuthenticated"]) {
                next("/");
            } else {
                next();
            }
    });
};

const redirectLogout = (to, from, next) => {
    store.dispatch("auth/logout")
        .finally(() => next("/prihlasit"));
};

Vue.use(Router);

export default new Router({
    mode: "history",
    saveScrollPosition: true,
    routes: [
        {
            path: "/",
            name: "stocks",
            component: Stocks,
            beforeEnter: requireAuthenticated,
        },
        {
            path: "/objednavka",
            name: "order",
            component: Order,
            beforeEnter: requireAuthenticated,
        },
        {
            path: "/objednavky",
            name: "orders",
            component: Orders,
            beforeEnter: requireAuthenticated,
        },
        {
            path: "/zakaznici",
            name: "customer-orders",
            component: CustomerOrders,
            beforeEnter: requireAuthenticated,
        },
        {
            path: "/cenniky",
            name: "files",
            component: Files,
            beforeEnter: requireAuthenticated,
        },
        {
            path: "/profil",
            name: "profile",
            component: Profile,
            beforeEnter: requireAuthenticated,
        },
        {
            path: "/prihlasit",
            name: "login",
            component: Login,
            beforeEnter: requireUnauthenticated,
        },
        {
            path: "/odhlasit",
            name: "logout",
            beforeEnter: redirectLogout,
        },
        {
            path: "/obchodne-podmienky",
            name: "conditions",
            component: Conditions,
            beforeEnter: requireAuthenticated,
        },
        {
            path: "*",
            name: "lost",
            component: Lost,
            beforeEnter: requireAuthenticated,
        },
    ]
});
