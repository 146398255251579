<template>
    <div id="lost-view">
        <div class="page-wrap d-flex flex-row align-items-center">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 text-center">
                        <span class="display-1 d-block">404</span>
                        <div class="mb-4 lead">Oops! Stránka nebola nájdená.</div>
                        <a href="/" class="btn btn-link">Spať na domovskú stránku</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "lost",
    };
</script>

<style>
    .page-wrap {
        min-height: 50vh;
    }
</style>
