<template>
    <b-container fluid id="files">
        <b-row>
            <b-col cols="12" offset-xl="2" xl="8">
                <b-field>
                    <b-input
                            v-model="search_params"
                            placeholder="Hľadať..."
                            type="search"
                            icon="magnify"
                            v-on:keyup.enter.native="load">
                    </b-input>
                    <b-button type="is-info" outlined v-on:click="load">Filter</b-button>
                    <b-select v-model="per_page" v-on:input="load" v-if="screen_width >= break_points[1]">
                        <option value="10">10 cenníkov</option>
                        <option value="20">20 cenníkov</option>
                        <option value="50">50 cenníkov</option>
                        <option value="75">75 cenníkov</option>
                        <option value="100">100 cenníkov</option>
                    </b-select>
                </b-field>
                <b-table
                        hoverable

                        :data="data"
                        :loading="loading"

                        paginated
                        backend-pagination
                        :total="total"
                        :per-page="per_page"
                        :current-page.sync="page"
                        @page-change="onPageChange"
                        aria-next-label="Ďalšia strana"
                        aria-previous-label="Predchádzajúca strana"
                        aria-page-label="Strana"
                        aria-current-label="Aktuálna strana"

                        backend-sorting
                        default-sort-direction="asc"
                        :default-sort="[sort_field, sort_order]"
                        @sort="onSort"

                >
                    <template slot-scope="props">
                        <b-table-column field="" label="" width="60">
                            <b-button
                                    type="is-info"
                                    icon-left="download"
                                    tag="a"
                                    :href="props.row.file"
                                    target="_blank"
                            />
                        </b-table-column>
                        <b-table-column field="file" label="Názov" sortable>
                            <b>{{ props.row.name }}</b>
                        </b-table-column>
                        <b-table-column
                                field="valid_until"
                                label="Platné do"
                                sortable
                                :visible="screen_width >= break_points[0]"
                        >
                            {{ props.row.valid_until }}
                        </b-table-column>
                        <b-table-column
                                field="create"
                                label="Nahraté"
                                sortable
                                :visible="screen_width >= break_points[1]"
                        >
                            {{ props.row.create }}
                        </b-table-column>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import files from "../api/files";

    import { BCol, BRow } from "bootstrap-vue";

    import BField from "buefy/src/components/field/Field";
    import BInput from "buefy/src/components/input/Input";
    import BButton from "buefy/src/components/button/Button";
    import BSelect from "buefy/src/components/select/Select";
    import BTable from "buefy/src/components/table/Table";
    import BTableColumn from "buefy/src/components/table/TableColumn";

    export default {
        name: "Files",
        components: {BCol, BRow, BTableColumn, BTable, BSelect, BButton, BInput, BField},
        data() {
            return {
                page: 1,
                total: 1,
                per_page: 20,
                sort_field: "create",
                sort_order: "asc",
                loading: false,
                search_params: null,
                data: [],
                screen_width: null,
                break_points: [360, 540],
            }
        },
        created () {
            this.load();
            this.getWidth();
            this.timer = setInterval(this.getWidth, 1000)
        },
        beforeDestroy () {
            clearInterval(this.timer)
        },
        methods : {
            getWidth: function() {
                this.screen_width = screen.width;
            },
            load: function () {
                this.loading = true;
                return files.getFiles({
                    ordering:`${(this.sort_order === "asc") ? "" : "-"}${this.sort_field}`,
                    page: this.page,
                    page_size: this.per_page,
                    search: this.search_params,
                })
                    .then(response => {
                        this.data = response.data.results || [];
                        this.total = response.data.count;
                    })
                    .catch( error => {
                        this.data = [];
                        this.total = 1;
                        this.page = 1;
                        throw error
                    })
                    .finally(() => {this.loading = false})
            },
            onPageChange(page) {
                this.page = page;
                this.load();
            },
            onSort(field, order) {
                this.sort_field = field;
                this.sort_order = order;
                this.load();
            },
        },
    }
</script>

<style scoped>
    #files {
        left: 5%;
        right: 5%;
    }
</style>