import session from "./session";

export default {
    getStocks(params) {
        return session.get("/v1/stocks/", {params: params})
    },
    checkStocks(ids) {
        return session.get("/v1/stocks/check/?ids=" + (ids || []).join(","))
    }
};
