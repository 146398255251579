<template>
    <div>
        <b-row id="login-row" align-v="center" align-h="center" align-content="center">
            <b-col cols="10" sm="8" md="6" lg="4" xl="2" align-self="center">
                <b-form @submit="login">
                    <b-card header-tag="header" footer-tag="footer" class="text-center">
                        <template v-slot:header class="card-header">
                            <img class="logo" src="../assets/logo.png" alt="Logo karki.sk">
                        </template>
                        <b-alert dismissible v-model="serverError" hide variant="danger">
                            Chyba servera.
                        </b-alert>
                        <b-form-group
                                label="Prihlasovacie meno"
                                label-for="username"
                                invalid-feedback="Nesprávne meno alebo heslo."
                                :state="userValidation"
                        >
                            <b-form-input
                                    id="username"
                                    type="username"
                                    v-model="username"
                                    :state="userValidation"
                                    trim
                                    required
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                                label="Heslo"
                                label-for="password"
                                invalid-feedback="Nesprávne meno alebo heslo."
                                :state="userValidation"
                        >
                            <b-form-input
                                    id="password"
                                    type="password"
                                    v-model="password"
                                    :state="userValidation"
                                    trim
                                    required
                            ></b-form-input>
                        </b-form-group>
                        <b-button block variant="primary" type="submit">Prihlásiť</b-button>
                        <template v-slot:footer class="card-footer">
                            <p class="mt-2 mb-2 text-light">© 2020 Karki, spol. s r.o., Všetky práva vyhradené.</p>
                        </template>
                    </b-card>
                </b-form>
            </b-col>
        </b-row>
    </div>
</template>

<style type="text/css">
    #login-row {
        height: 100vh;
    }
    #login-row .card-header {
        background-color: #373737;
    }
    #login-row .card-footer {
        background-color: #373737;
    }
    #login-row .logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
</style>

<script>
    export default {
        name: "login",
        data() {
            return {
                username : "",
                password : "",
                userValidation: null,
                serverError: false
            }
        },
        methods: {
            login: function (event) {
                event.preventDefault();

                this.$store.dispatch("auth/login", { username: this.username, password: this.password })
                    .then(() => this.$router.push("/"))
                    .catch(error => {
                        if (error.response.status === 401 || error.response.status === 405) {
                            this.userValidation = false;
                        } else {
                            this.serverError = true;
                        }
                    })
            }
        },
    }
</script>
