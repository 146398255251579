import auth from "../api/auth";
import session from "../api/session";

import {
    LOGIN_BEGIN,
    LOGIN_FAILURE,
    LOGIN,
    LOGOUT,
} from "./types";

const IS_AUTHENTICATED = "IS_AUTHENTICATED";
const IS_STAFF = "IS_STAFF";
const IS_MANAGEMENT = "IS_MANAGEMENT";

const initialState = {
    is_authenticated: localStorage.getItem(IS_AUTHENTICATED) || false,
    is_staff: localStorage.getItem(IS_STAFF) || false,
    is_management: localStorage.getItem(IS_MANAGEMENT) || false,
    error: null,
};

const getters = {
    isAuthenticated: state => state.is_authenticated,
    isStaff: state => state.is_staff,
    isManagement: state => state.is_management,
};

const actions = {
    login({ commit }, { username, password }) {
        commit(LOGIN_BEGIN);
        return auth.login(username, password)
            .then(({ data }) => commit(LOGIN, data))
            .catch( error => {
                commit(LOGIN_FAILURE, error);
                throw error;
            });
        },
    logout({ commit }) {
        return auth.logout()
            .finally(() => commit(LOGOUT));
        },
    isLoggedIn({ commit }){
        return auth.profile()
            .then(({ data }) => commit(LOGIN, data))
            .catch(() => commit(LOGOUT));
    },
};

const mutations = {
    [LOGIN_BEGIN](state) {
        state.is_authenticated = false;
        state.error = null;
    },
    [LOGIN_FAILURE](state, error) {
        state.is_authenticated = false;
        state.error = error;
    },
    [LOGIN](state, {is_staff, is_management}) {
        localStorage.setItem(IS_AUTHENTICATED, "True");
        localStorage.setItem(IS_STAFF, is_staff ? "True": "False");
        localStorage.setItem(IS_MANAGEMENT, is_management ? "True": "False");

        state.is_authenticated = true;
        state.is_staff = is_staff;
        state.is_management = is_management;
        state.error = null;
    },
    [LOGOUT](state) {
        localStorage.removeItem(IS_AUTHENTICATED);
        localStorage.removeItem(IS_STAFF);
        localStorage.removeItem(IS_MANAGEMENT);
        delete session.defaults.headers.Authorization;

        state.is_authenticated = false;
        state.is_staff = false;
        state.is_management = false;
        state.error = null;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};