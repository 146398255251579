<template>
    <div id="ws-nav">
        <b-navbar toggleable="lg" type="dark" variant="dark" fixed="top">
            <b-navbar-brand href="/">
                <img class="img-responsive" src="../assets/logo.png" alt="Logo karki.sk">
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item href="/"><strong>Zásoby</strong></b-nav-item>
                    <b-nav-item href="/objednavky"><strong>Objednávky</strong></b-nav-item>
                    <b-nav-item v-if="isStaff" href="/zakaznici"><strong>Objednávky zákazníci</strong></b-nav-item>
                    <b-nav-item href="/profil"><strong>Profil</strong></b-nav-item>
                    <b-nav-item href="/cenniky"><strong>Cenníky</strong></b-nav-item>
                </b-navbar-nav>

                <b-navbar-nav class="ml-auto">
                    <b-button-group size="md">
                        <b-button variant="primary" icon-left="cart" href="/objednavka">
                            <strong>Košík </strong>
                            <b-badge v-if="cart_size" variant="light">{{ cart_size }}</b-badge>
                        </b-button>
                        <b-button v-if="isStaff" variant="warning" icon-left="settings" href="/v1/ws-secure-admin/">
                            <strong>Správca</strong>
                        </b-button>
                        <b-button variant="outline-danger" outlined href="/odhlasit">
                            <strong>Odhlásiť</strong>
                        </b-button>
                    </b-button-group>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "navbar",
        computed: {
            ...mapGetters("auth", {
                isStaff: "isStaff",
                isManagement: "isManagement",
            }),
        },
        data() {
            return {
                cart_size: null,
            }
        },
        created: function () {
            this.cart_refresh = setInterval(() => this.get_cart_size(), 500);
        },
        beforeDestroy: function() {
            clearInterval(this.cart_refresh);
        },
        methods: {
            get_cart_size: function () {
                this.cart_size = Object.keys(this.$store.state.cart.cart || {}).length;
            }
        }
    };
</script>

<style>
    .img-responsive {
        height: 40px;
    }
    #ws-nav nav {
        background-color: #373737 !important;
    }
</style>