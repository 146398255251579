<template>
    <b-container id="conditions">
        <b-jumbotron header="Obchodné podmienky">
            <p>
                <strong>Všeobecné ustanovenia</strong><br>
                Zmluvné strany&nbsp;<br>
                1) Predávajúci je prevádzkovateľ internetového obchodu (ďalej len eshop) – firma Karki spol. s r.o.&nbsp;<br>
                2) Kupujúci môže byť každá fyzická alebo právnická osoba za predpokladu, že sa bude riadiť týmito Obchodnými podmienkami.<br>
                3) Tovar - pod týmto pojmom sa rozumie každý druhy tovaru, ktorý je zaevidovaný na stránke eshopu a je určený na predaj.&nbsp;<br>
                <br>
                <strong>Vymedzenie pojmov&nbsp;</strong><br>
                1) Všetky vzťahy medzi kupujúcim a predávajúcim, ktoré nie sú upravené týmito všeobecnými obchodnými podmienkami, sa riadia príslušnými ustanoveniami zákona č. 513/1991 Zb. Obchodný zákonník v znení neskorších predpisov. Ak je Kupujúci spotrebiteľom v zmysle § 52 ods. 3 zákona č. 40/1964 Zb. Občiansky zákonník v platnom znení, teda ak sa jedná o Kupujúceho, ktorý pri uzatváraní a plnení zmluvy nejedná v rámci svojej obchodnej alebo inej podnikateľskej činnosti, riadia sa vzťahy neupravené týmito všeobecnými obchodnými podmienkami príslušnými ustanoveniami zákona č. 40/1964 Zb. Občiansky zákonník v znení neskorších predpisov.<br>
                <br>
                2) Kupujúci má právo vo vzťahu k predávajúcemu vyplývajúce zo zákona č. 102/2014 Z.z. o&nbsp;ochrane spotrebiteľa alebo zmluvy uzavretej na diaľku alebo zmluvy uzavretej mimo prevádzkových priestorov predávajúceho.
            </p>
            <p>
                <br>
                <strong>Informácie o tovare</strong><br>
                Záručné podmienky<br>
                1) Záručné podmienky sa riadia ustanoveniami Občianskeho zákonníka č. 40/1964 Zb. v platnom znení. Záručná doba je 24 mesiacov, pokiaľ nie je na konkrétnom tovare uvedené inak a začína plynúť dňom prevzatia tovaru objednávateľom.&nbsp;<br>
                2) Zaslanú faktúru si starostlivo uschovajte.<br>
                3) Záručná doba sa predlžuje o dobu, po ktorú kupujúci nemohol používať tovar z dôvodu záručnej opravy tovaru.<br>
                4) V prípade výmeny tovaru za nový tovar dostane kupujúci doklad, na ktorom bude uvedený vymenený tovar. Prípadné ďalšie reklamácie sa uplatňujú na základe pôvodnej faktúry a reklamačného protokolu. V prípade výmeny tovaru za nový začína plynúť záručná doba na nový tovar nanovo od jeho prevzatia.<br>
                5) Záruka zaniká poškodením tovaru neodborným používaním alebo iným neštandardným zaobchádzaním.<br>
                <br>
                <strong>Dodacia lehota</strong><br>
                Informácie o dodacej lehote daného výrobku sa nachádzajú pri jeho obrázku v položke Dostupnosť pod jeho cenou a znamená: kedy je/bude tovar na sklade.<br>
                2) Skladom:<br>
                Tovar je u nás na sklade. Tovar bude odoslaný štandardne do 24 hodín od prevzatia objednávky v pracovné dni. Náš zákaznícky servis vám odošle email s informáciou o vyexpedovaní tovaru.&nbsp;<br>
                3) 7 - 30 dní (na objednávku)<br>
                Tovar je na objednávku. Náš zakaznícky servis preverí jeho dostupnosť v centrálnom sklade a štandardne do 24 hodín od prevzatia objednávky v pracovné dni vám odošle email kde vám potvrdí termín dodania a ostatné informácie.<br>
                <br>
                <strong>Cena tovaru</strong><br>
                Všetky ceny na stránke&nbsp;<a href="http://velkoobchod.karki.sk/" title="Linkification: http://velkoobchod.karki.sk/">velkoobchod.karki.sk</a>&nbsp;sú zmluvné, konečné a sú uvádzané s DPH.<br>
                <br>
                Cena tovaru je pevne stanovená na každej skladovej položke a kupujúci ju nemôže žiadnym spôsobom upravovať alebo meniť.<br>
                Predávajúci si vyhradzuje právo možnosti upraviť (zvýšiť/znížiť) cenu tovaru. Platnosť cien tovaru je deň zverejnenia na stránke eshopu.&nbsp;<br>
                Ak kupujúci s cenou tovaru nesúhlasí, môže od zmluvy odstúpiť.<br>
                <br>
                <strong>Informácie o procese objednávania&nbsp;</strong><br>
                <strong>Objednávka tovaru</strong><br>
                1) Kupujúci, ktorý si vyberie tovar z nášho eshopu uzavrie košík a pošle elektronickú správu, týmto krokom potvrdí objednávku tovaru.&nbsp;<br>
                2) Predávajúci je povinný potvrdiť emailom kupujúcemu registráciu objednávky v systéme eshopu. Ak nastanú nezrovnalosti, predávajúci upozorní kupujúceho a prípadne podá bližšie informácie alebo ponúkne náhradu.&nbsp;<br>
                3) Naši pracovníci sú pripravení zodpovedať na Vaše otázky na našich kontaktných mailových, resp. telefónnych číslach. Na našich stránkach nájdete tipy a poradenstvo k použitiu našich dekoratívnych materiálov. Máme pracovníkov a spolupracovníkov odborne vyškolených pre realizáciu kompletných finálnych úprav Vašich stien , čiže môžeme Vami objednané produkty dodať aj s realizáciou, na základe zmluvy o dielo.<br>
                <br>
                Odporúčanie: Pri objednávke tovaru si podrobne preštudujte návod ako sa v praxi konkrétny tovar používa (tapety na steny, samolepiace fólie na dvere), aké sú pracovné postupy (napr. tapetovanie, príprava podkladu).<br>
                <br>
                <strong>Zrušenie objednávky – odstúpenie od zmluvy</strong>&nbsp;<br>
                1) Zrušenie zo strany kupujúceho<br>
                Ak si prajete zrušiť objednávku, ktorá ešte nebola vybavená, čo najskôr nás prosím kontaktujte na&nbsp;<a href="mailto:info@karki.sk" title="Linkification: mailto:info@karki.sk">info@karki.sk</a>&nbsp;a uveďte číslo Vašej objednávky. Ak už bola objednávka zaplatená alebo vybavená, kupujúci môže vrátiť naspäť tovar bez udania dôvodu do 14 dní odo dňa prevzatia tovaru, ak predávajúci včas a riadne splnil informačné povinnosti podľa § 7 ods.1 č. 102/2014 Z.z. Použite prosím formulár na odstúpenie od zmluvy na spodnej časti stránky. Predávajúci je povinný prevziať tovar späť v&nbsp;zmysle § 9 ods.1 zákona č. 102/2014 Z.z., podľa ktorého povinnosťou predávajúceho je bez zbytočného odkladu, najneskôr do 14 dní odo dňa doručenia vrátiť všetky platby prijaté od spotrebiteľa od prijatia oznámenia o odstúpení od zmluvy. Náklady na vrátenie tovaru znáša spotrebiteľ len v tom prípade, ak výrobok plne zodpovedal kvalitatívnym požiadavkám a nebol vadný.&nbsp;<br>
                &nbsp;
            </p>
            <p>
                2) Zrušenie zo strany predávajúceho<br>
                Predávajúci si vyhradzuje právo zrušiť objednávku, alebo jej časť v týchto prípadoch:&nbsp;<br>
                - tovar označí výrobca, že sa už nevyrába a z tohto dôvodu ho nie je možné dodať,&nbsp;<br>
                - výrobca výrazným spôsobom zmenil cenu tovaru.&nbsp;<br>
                V prípade, že táto situácia nastane, predávajúci bude kontaktovať kupujúceho za účelom dohody o ďalšom postupe. V prípade, že kupujúci zaplatil časť alebo celú čiastku kúpnej ceny, bude mu táto čiastka vrátená späť. Kupujúci bude o tomto kroku informovaný telefonicky alebo e-mailom a peniaze mu budú vrátené najneskôr do 15 dní prevodom na účet, ktorý nám pošlete do emailu, pokiaľ sa spolu nedohodneme inak.<br>
                <br>
                <strong>Dodacie podmienky</strong><br>
                Expedičný poplatok<br>
                Expedičný poplatok určuje predávajúci a v tejto sume je zahrnuté balné a prepravné v celkovej výške. Pri preprave nad 60 € je doprava zdarma.<br>
                Pre dodanie tovaru na Slovensko<br>
                hodnota objednaného tovaru bez DPH/ € cena expedície<br>
                do 60 € je &nbsp; 4,5 €,&nbsp; nad 60 € zdarma&nbsp;<br>
                <br>
                Pre dodanie tovaru do Českej Republiky<br>
                hodnota objednaného tovaru bez DPH/ € cena expedície<br>
                do 60 € 4,5 €, nad 60 € zdarma&nbsp;<br>
                <br>
                <strong>Platobné podmienky</strong><br>
                Predávajúci akceptuje 3 spôsoby platenia, ktoré si zmluvné strany záväzne dohodli pri potvrdení objednávky:&nbsp;<br>
                1) Platba Predfaktúrou&nbsp;<br>
                na váš e-mail bude doručená predfaktúra za objednaný tovar, ktorý bude expedovaný po jej zaplatení. Ak zákazník neuhradí predfaktúru do 14 dní, objednávka je považovaná za neplatnú a zároveň zaniká obchodný vzťah medzi zákazníkom a spoločnosťou Karki spol. s r.o..<br>
                2) Platba Dobierkou<br>
                kupujúci zaplatí celu sumu tovaru pri preberaní balíka kuriérovi prepravnej spoločnosti, ktorá mu balík doručí.&nbsp;<br>
                Kuriér preverí totožnosť kupujúceho na základe predloženého dokladu.&nbsp;<br>
                3) Online - platbou cez internet formou Tatrapay, prípadne Cardpay.<br>
                <br>
                <strong>Záručný list</strong><br>
                Ku každej zásielke bude priložená faktúra, ktorá nahrádza záručný list.<br>
                <br>
                <strong>Dodacie podmienky&nbsp;</strong><br>
                1) Dodacia lehota objednaného tovaru od zaevidovania objednávky po jeho prevzatie kupujúcim je stanovená podľa druhu tovaru, ktorý si kupujúci vybral v našom eshope :<br>
                tovar na sklade - od dátumu potvrdenia objednávky do 48 hod (v pracovných dňoch).<br>
                tovar na objednávku - od dátumu potvrdenia objednávky 7-30 dní. Ponuka nášho internetového obchodu je veľmi širká, preto Vám dodaciu dobu potvrdíme písomne na Váš email.&nbsp;<br>
                <br>
                2) Spôsoby dodania tovaru&nbsp;<br>
                Tovar, ktorý ste si na našom eshope objednali, Vám doručí kuriér do 24 hod. od odoslania zásielky v internetovom obchode ako balík na miesto dodania, tak ako ste ho uviedli v objednávke. V deň dodania zásielky sa kuriér s kupujúcim telefonicky skontaktuje a dohodne podrobnosti prevzatia zásielky.<br>
                <br>
                <strong>Stornovanie objednávky</strong><br>
                Objednávku možno stornovať do 24 hodín od objednania, a to prostredníctvom emailu alebo telefonicky. Takúto storno objednávku považujeme za zrušenú. Pri zrušení objednávky je potrebné uviesť vaše meno, email a číslo objednávky.&nbsp;<br>
                <br>
                <strong>Penalizácie</strong><br>
                Neprebratá zásielka - tovar na objednávku<br>
                V prípade že, odoslanú zásielku si kupujúci nevyzdvihne, bude predávajúci požadovať úhradu nákladov spojených so zasielaním objednávky a manipulačný poplatok v celkovej výške 10 €. V takomto prípade zašle predávajúci kupujúcemu doklad s údajmi potrebnými k úhrade vzniknutých nákladov.&nbsp;<br>
                <br>
                <strong>Ochrana osobných údajov spotrebiteľa</strong><br>
                1) Zmluvné strany sa dohodli, že kupujúci v prípade, že je fyzickou alebo právnickou osobou je povinný oznámiť predávajúcemu svoje údaje v registračnom formulári:<br>
                Kupujúci a predávajúci sa dohodli, že kupujúci v prípade, že je fyzickou osobou je povinný oznámiť predávajúcemu svoje meno a priezvisko, adresu trvalého bydliska vrátane PSČ, číslo telefónu a e-mailovú adresu.<br>
                Kupujúci a predávajúci sa dohodli, že kupujúci v prípade, že je právnickou osobou alebo živnostníkom je povinný oznámiť predávajúcemu svoje obchodné meno, adresu sídla vrátane PSČ, IČO, DIČ, číslo telefónu a e-mailovú adresu.<br>
                <br>
                2) Tieto informácie sú nutné pre vašu identifikáciu ako kupujúceho. Používame ich k realizácii a zaúčtovaniu Vašej platby za nakúpený tovar, na správnu distribúciu tovaru a pri komunikácií s Vami. Údaje môže kupujúci podľa aktuálneho stavu zmeniť po prihlásení do eshopu.<br>
                <br>
                3) Vaše údaje neposkytujeme žiadnym iným subjektom, s výnimkou organizácií, s ktorými je spolupráca nevyhnutná pre správne vybavenie Vašej objednávky. Sú to najmä banky ( v niektorých prípadoch poskytnutie Vášho mena), doručovateľské služby (adresa a telefón na dodanie) a anketné spoločnosti ako Heureka a pod. (poskytnutie Vášho emailu) pre zistenie Vašej spokojnosti s nákupom.<br>
                Tieto údaje sú však vždy poskytnuté jednorazovo pre vybavenie Vašej konkrétnej objednávky.<br>
                <br>
                4) Databázu údajov nepredávame, neprenajímame a ani inak neposkytujeme žiadnej tretej strane.<br>
                <br>
                5) Využívaním internetového obchodu&nbsp;<a href="http://velkoobchod.karki.sk/" title="Linkification: http://velkoobchod.karki.sk">velkoobchod.karki.sk</a>&nbsp;kupujúci súhlasí so zhromažďovaním a používaním informácií o ňom a o jeho nákupoch v znení Zákona č. 428/2002 Z.z. o ochrane osobných údajov v informačných systémoch. Kupujúci udeľuje Predávajúcemu tento súhlas na dobu neurčitú a môže ho písomnou formou kedykoľvek odvolať. Súhlas zanikne v lehote 1 mesiaca od doručenia odvolania súhlasu Predávajúcemu. Pravidlá zaobchádzania s týmito údajmi sú popísané v tomto dokumente.
            </p>
        </b-jumbotron>
    </b-container>
</template>

<script>
    import { BContainer, BJumbotron } from "bootstrap-vue";

    export default {
        name: "Conditions",
        components: {
            BContainer, BJumbotron,
        }
    }
</script>

<style scoped>

</style>